exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-cyber-security-for-charities-js": () => import("./../../../src/pages/cyber-security-for-charities.js" /* webpackChunkName: "component---src-pages-cyber-security-for-charities-js" */),
  "component---src-pages-cyber-security-js": () => import("./../../../src/pages/cyber-security.js" /* webpackChunkName: "component---src-pages-cyber-security-js" */),
  "component---src-pages-docs-cookie-policy-js": () => import("./../../../src/pages/docs/cookie-policy.js" /* webpackChunkName: "component---src-pages-docs-cookie-policy-js" */),
  "component---src-pages-docs-privacy-policy-js": () => import("./../../../src/pages/docs/privacy-policy.js" /* webpackChunkName: "component---src-pages-docs-privacy-policy-js" */),
  "component---src-pages-docs-sustainability-statement-js": () => import("./../../../src/pages/docs/sustainability-statement.js" /* webpackChunkName: "component---src-pages-docs-sustainability-statement-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

